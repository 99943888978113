<template>
  <div>
    <!-- <div class="d-flex justify-content-start align-items-center my-10">
      Toplam {{ totalItems }} müşteri bulundu.
    </div> -->
    <div
      class="d-flex align-items-center justify-content-between search-list-area"
    >
      <div class="d-flex align-items-center search-input mb-4">
        <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
        <select v-model="list_length" class="form-control ml-2">
          <option value="" selected>Seçiniz</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
        <b-button variant="outline-primary" class="d-flex d-md-inline-block mt-0 w-md-auto w-100 mb-6"  v-b-toggle.sidebar-right>Filtrele</b-button>
    </div>
    <div class="">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Varlık Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.id="{ item }">
          <router-link :to="`find/detail/${item._id.$oid}/`">
            <button
            class="btn btn-sm btn-light"
          >
            <i class="flaticon2-next text-dark p-0"></i>
          </button>
          </router-link>
        </template>
        <template v-slot:item.name="{ item }">
          <div class="badges-area">
            <div class="badges">
              <i v-if="item.status === '1' " class="badge green"></i>
              <i v-if="item.watch" class="badge yellow"></i>
            </div>
            <p>{{ item.name }}</p>
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <Steps :steps="3" :activeStep="parseInt(item.instep)" :stepNames="stepNames" />
        </template>
        <template v-slot:item.tapu="{ item }">
          <div class="tapu-text">
            <h6>{{ item.tapu }}</h6>
            <div class="d-inline-flex flex-column">
              <span>Taşınır: <strong>{{ item.moveable_count }}</strong> 
            </span>
            <span class="mt-2">Taşınmaz:
              <strong>{{ item.real_count }}</strong></span>
            </div>
          </div>
        </template>
        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex px-4">
            <router-link
              :to="`properties/detail`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="fas fa-city p-0" :class="[item?.reminders ? 'text-primary' : 'text-dark']"></i
              ></b-button>
            </router-link>
            <router-link
              :to="`/adverts/find/${item._id.$oid}`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="fas fa-camera p-0" :class="[item?.adverts ? 'text-primary' : 'text-dark']"></i
              ></b-button>
            </router-link>

            <router-link
              :to="`/declaration/detail`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="fas fa-file-signature  p-0" :class="[item?.declaration ? 'text-primary' : 'text-dark']"></i
              ></b-button>
            </router-link>

            <router-link
              :to="`/transfer/edit`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="fas fa-money-check-alt p-0" :class="[item?.sales ? 'text-primary' : 'text-dark']"></i
              ></b-button>
            </router-link>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
    <div class="notes-sidebar top-full">
        <b-sidebar id="sidebar-right" right>
          <div class="card dveb-gray-card aside-gray-card tab-card" style="overflow-y:auto">
            <div class="card-header">
              FİLTRELE
              
            </div>
            <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="Filtre" active>
                <div class="form-group">
                    <label for="sahip">Sahibi</label>
                    <select name="" id="sahip" class="form-control" >
                        <option value="" selected>Seçiniz</option>
                        <option value="">Şekerbank A.Ş</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="izle">İzlenecek</label>
                    <select name="" id="izle" class="form-control" >
                        <option value="" selected>Seçiniz</option>
                        <option value="">Evet</option>
                        <option value="">Hayır</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="durum">Durum</label>
                    <select name="" id="durum" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                    </select>
                    
                </div>
                <div class="form-group">
                    <label for="il">İl</label>
                    <select name="" id="il" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                    </select>
                    
                </div>
                <div class="form-group">
                    <label for="ilce">İlçe</label>
                    <select name="" id="ilce" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                    </select> 
                </div>
                <div class="form-group">
                    <label for="anahat">Anahtar Durumu</label>
                    <select name="" id="anahat" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">Var</option>
                        <option value="">Yok</option>
                        <option value="">3</option>
                    </select> 
                </div>
                <div class="form-group">
                    <label for="anahat" class="form-label">İşgalci Durumu</label>
                    <select name="" id="anahat" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">Var</option>
                        <option value="">Yok</option>
                        <option value="">3</option>
                    </select> 
                </div>
                <div class="form-group">
                    <label for="anahat">Ada</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label for="anahat">Parsel</label>
                    <input type="text" class="form-control">
                </div>
                <div class="row">
                    <div class="col-md-6 col-12 mt-4">
                        <b-button variant="light" class="w-100" >
                            Temizle
                        </b-button>
                    </div>
                    <div class="col-md-6 col-12 mt-4">
                        <b-button variant="light" class="w-100">
                            Uygula
                        </b-button>
                    </div>
                    <div class="col-12 mt-4">
                        <b-button variant="light" class="w-100">
                            Bu Filtreyi Kaydet
                        </b-button>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Hızlı Ara">
                <div
                class="card mt-4 dveb-card-white"
                style="border-radius: 0 !important"
              >
                <div>
                  <h4> Filtre Örnek </h4>
                  <hr>
                  <div class="row ml-auto">
                    <b-button variant="success" class="mr-4" >
                      Uygula
                    </b-button>
                    <b-button variant="danger" class="" @click="openDeleteHandler(key)">
                      Sil
                    </b-button>
                  </div>
                </div>
            </div>
            </b-tab>
          </b-tabs>

          </div>
        </b-sidebar>
    </div>
    <DeleteOperation
    />
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { PROPERTIES } from "@/core/services/store/property/property.module";


export default {
  name: "customerList",
  components: {
    Steps: () => import("@/components/global/StepBar"),
  },
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames : ["Hazırlık","Satış","Devir"],
      header: [
        { text: "", value: "id", size: "50px" },
        { text: "Varlık", sortable:true,value: "name" },
        { text: "Sahiplik", sortable:true,value: "owner_name" },
        { text: "Tapu Bilgileri", value: "tapu" },
        { text: "Durum", sortable:true,value: "status" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination : {
              current_page: 0,
              total_pages: 0,
              total_items: this.totalItems,
              page:1
      },
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList:[],
      list_length:10,
      page : 1,
    };
  },

  created() {
    this.search = this.$route.query.search
    this.search = this.search?.toLowerCase()
    this.getList();

  },
  methods: {
    getList() {
      

      this.items = [];
      this.totalItems = 0;
      this.loading = true


      let queryLimit = this.list_length ? this.list_length : 10;

      let start = (this.pagination.page -1 )* queryLimit
      let end = (this.pagination.page * queryLimit)-1

      let query = `property/property?start=${start}&end=${end}&limit=${queryLimit}&user_token=${this.myUser}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }
      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.status.code == 200) {
            this.$store.dispatch(PROPERTIES, res.response.data);
            this.items = res.response.data;
            this.totalItems = res.response?.count;
            this.pagination = {
              current_page: this.pagination.page?this.pagination.page:1,
              page: this.pagination.page?this.pagination.page:1,
              total_pages: Math.ceil(this.totalItems /this.list_length),
              total_items: this.totalItems,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show('modal-deleteOperation');
      let index = this.notes.indexOf(key)
      this.notes.splice(index,1)
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function () {
      if(this.search?.length > 0){
        this.getList();
      }
    },
    list_length:function(){
      console.log("calisti")
      this.getList()
    },
    $route:function(){
      this.search = this.$route.query.search
    this.search = this.search.toLowerCase()
    }
    

  },
  computed: {
    ...mapGetters(["getProperties","myUser"]),
  },
  mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Varlıklar / Bul", route: "/properties/find" },
      ]);
    },
};
</script>
